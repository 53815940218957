.container{
      background-color: #f5f5f5;
      min-height: 100vh;
      background-image: url('../../assets/images/myimage.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-attachment: fixed;
      position: relative;
}
 .box{
    border-radius: 0.8rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-block: 0.5rem ;
    margin-inline: 2rem;
    background-size: 100% 100%;
    margin-bottom: 0 !important;
} 

 .imageContainer{
    text-align: center;
     height: 7rem; 
    top: 0;
    align-items: center;  
    background-color: white; 
    position: sticky;
 } 
 .gammeChoix{
   font-family: 'Montserrat semibold' sans-serif !important;
    color:#2D2D2D;
    font-weight: 500;
    margin-bottom: 3rem;
    margin-top: 5rem;
 }
 







   
