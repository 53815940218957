.card{
    background-color:#FFFFFF ;
    height: 14.8rem; 
    padding: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    margin-inline: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15); */
    
}
.card:hover{
   /* border:1px solid rgba(102, 132, 181, 1);
   box-shadow: 4px 4px 4px  rgb(114, 114, 114); */
   /* transform: scale(1.1);
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
   border:2px solid rgba(102, 132, 181, 1);

}
 
.logo{
    width: 6rem;
    height:6rem;
    padding-top: 3rem;
    margin-top: 0.5rem;
    object-fit: fill;
}
.titre{
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 2rem;
    color:#4B4B4B
}